.App {
  height: 100vh;
  background-image: url('boxes.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

input[type="text"],
input[type="time"] {
  padding: 5px;
}

button {
  padding: 10px 20px;
  background-color: #4caf50;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #45a049;
}

.timer-text {
  font-size: 6vw;
  color: white;
}

.event-name {
  font-size: 8vw;
  color: rgb(206, 161, 161);
}